










































import CloseLine from '@/app/ui/assets/close_line.vue'
import { Prop, Vue, Component } from 'vue-property-decorator'

@Component({
  components: { CloseLine },
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
})

export default class CategoryImage extends Vue {
  @Prop({ type: String }) private accept?: string
  @Prop({ default: 'category-image', type: String }) private id?: string
  @Prop({ type: String, default: '' }) private preview?: string
  @Prop({ type: Boolean, default: false }) private isError!: boolean
  @Prop({ type: String, default: '' }) private errorMessage!: string
  @Prop({ type: String, default: '' }) private description!: string
  @Prop({ type: Number, default: 120 }) private maxSize!: number

  filename = {}

  private onInputChange($event: { target: { value: string, files: { name: string, size: number }[] } }) {
    const files = $event.target.files[0]
    const size = files?.size / 1000
    try {
      if (size > this.maxSize) {
        $event.target.value = ""
        throw new Error(`File size exceeds ${this.maxSize}KB`)
      }
      this.$emit('change', files)
      $event.target.value = ""
    } catch (e) {
      const error = e as { message: string }

      // Show error alert
      Vue.notify({
        title: 'Error',
        text: error.message,
        type: 'error',
        duration: 3000,
      })
    }
  }

  private onDeleteImg() {
    this.$emit('change', null)
  }
}
