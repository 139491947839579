import { FAQSubCategory, QnA, QnASubCategory, StarQuestion } from '@/domain/entities/FAQ'
import {
  CreateFAQRequestInterface,
  UpdateFAQRequestInterface,
  UpdateFAQCategoryOrderRequestInterface,
  UpdateFAQCategoryStatusRequestInterface,
  UpdateFAQDraftStatusRequestInterface,
  UploadFAQCategoryImageRequestInterface,
  ReorderStarQuestionRequestInterface,
} from '@/data/payload/contracts/FAQRequest'

export class CreateFAQRequest implements CreateFAQRequestInterface {
  public categoryName?: string
  public isActive?: boolean
  public withSubCategory?: boolean
  public iconImage?: string
  public data?: QnA[]
  public subCategoryList?: FAQSubCategory[]

  constructor(
    categoryName?: string,
    isActive?: boolean,
    withSubCategory?: boolean,
    iconImage?: string,
    data?: QnA[],
    subCategoryList?: FAQSubCategory[],
  ) {
    this.categoryName = categoryName
    this.isActive = isActive
    this.withSubCategory = withSubCategory
    this.iconImage = iconImage
    this.data = data
    this.subCategoryList = subCategoryList
  }

  public toPayload(): string {
    const data = {
      category_name: this.categoryName,
      is_active: this.isActive,
      with_sub_category: this.withSubCategory,
      icon_image: this.iconImage,
      data: this.data?.map(item => ({
        question: item.question,
        answer: item.answer,
        order: item.order,
      })),
      sub_category_list: this.subCategoryList?.map(item => ({
        sub_category_name: item.subCategory,
        order: item.order,
        data: item.data?.map(unit => ({
          question: unit.question,
          answer: unit.answer,
          order: unit.order,
        }))
      }))
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateFAQRequest implements UpdateFAQRequestInterface {
  public categoryName?: string
  public isActive?: boolean
  public data?: QnA[]
  public withSubCategory?: boolean
  public subCategoryList?: QnASubCategory[]
  public iconImage?: string

  constructor(
    categoryName?: string,
    isActive?: boolean,
    data?: QnA[],
    withSubCategory?: boolean,
    subCategoryList?: QnASubCategory[],
    iconImage?: string,
  ) {
    this.categoryName = categoryName
    this.isActive = isActive
    this.data = data
    this.withSubCategory = withSubCategory
    this.subCategoryList = subCategoryList
    this.iconImage = iconImage
  }

  public toPayload(): string {
    const data = {
      category_name: this.categoryName,
      is_active: this.isActive,
      data: this.data?.map(item => ({
        id: item.id,
        question: item.question,
        answer: item.answer,
        order: item.order,
        is_draft: item.isDraft,
      })) || [],
      with_sub_category: this.withSubCategory,
      sub_category_list: this.subCategoryList?.map(item => ({
        id: item.id,
        sub_category_name: item.subCategoryName,
        order: item.order,
        data: item.data?.map(unit => ({
          id: unit.id,
          question: unit.question,
          answer: unit.answer,
          order: unit.order,
          is_draft: unit.isDraft,
        }))
      })) || [],
      icon_image: this.iconImage
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateFAQCategoryOrderRequest
  implements UpdateFAQCategoryOrderRequestInterface {
  public toOrderNumber?: number

  constructor(toOrderNumber?: number) {
    this.toOrderNumber = toOrderNumber
  }

  public toPayload(): string {
    const data = {
      to_order_number: this.toOrderNumber,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateFAQCategoryStatusRequest
  implements UpdateFAQCategoryStatusRequestInterface {
  public isActive?: boolean

  constructor(isActive?: boolean) {
    this.isActive = isActive
  }

  public toPayload(): string {
    const data = {
      is_active: this.isActive,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateFAQDraftStatusRequest
  implements UpdateFAQDraftStatusRequestInterface {
  public faqId?: number
  public isDraft?: boolean

  constructor(faqId?: number, isDraft?: boolean) {
    this.faqId = faqId
    this.isDraft = isDraft
  }

  public toPayload(): string {
    const data = {
      faq_id: this.faqId,
      is_draft: this.isDraft,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UploadFAQCategoryImageRequest implements UploadFAQCategoryImageRequestInterface {
  public image?: File

  constructor(
    image?: File
  ) {
    this.image = image
  }

  public toPayload(): FormData {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('icon_image', this.image as File)

    return data
  }
}

export class ReorderStarQuestionRequest implements ReorderStarQuestionRequestInterface {
  public payload?: StarQuestion[]

  constructor(payload?: StarQuestion[]) {
    this.payload = payload
  }

  public toPayload(): string {
    const payload = this.payload?.map((item) => ({
      faq_id: item.id,
      star_order: item.starOrder,
      icon_image: item.iconImage
    }))
    return JSON.stringify(payload)
  }
}
