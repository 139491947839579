










































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import DragDisabledIcon from '@/app/ui/assets/icon_drag_disabled.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  inheritAttrs: false,
  components: { VueEditor, CaretDownIcon, TrashIcon, DragIcon, DragDisabledIcon, TextInput },
})
export default class QnAItem extends Vue {
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private order!: number
  @Prop({ required: true }) private questionValue!: string
  @Prop({ default: '' }) private questionErrorMessage!: string
  @Prop({ default: '' }) private answerErrorMessage!: string
  @Prop({ required: true }) private answerValue!: string
  @Prop({ default: false }) private showDelete!: boolean
  @Prop({ default: true }) private isDefaultOpen!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: false }) private isAnswerError!: boolean
  @Prop({ default: false }) private isQuestionError!: boolean
  @Prop({ default: 140 }) private maxQuestionCount!: 100
  @Prop({ default: 1000 }) private maxAnswerCount!: 1000

  isShowAnswer = this.isDefaultOpen
  customToolbar = [
    ['bold', 'italic'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [
      { list: 'ordered' },
      { list: 'bullet' },
    ],
    ['link'],
    ['clean'],
  ]

  private stripTags(input: string) {
    return Utils.stripTags(input)
  }

  private onToggleAnswer() {
    this.isShowAnswer = !this.isShowAnswer
  }
}
