






















import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Button from '../Button/index.vue'
import Modal from '../Modal/index.vue'

@Component({
  components: {
    Button,
    Modal,
  },
})
export default class LeaveModal extends Vue {
  @Prop({ default: false }) private visible!: boolean

  @Watch('visible')
  onVisibleChanged(val: boolean) {
    this.isVisible = val
  }
  isVisible = this.visible

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private onLeavePage() {
    this.isVisible = false
    this.$emit('leavepage')
  }

  private hideModal() {
    this.isVisible = false
    this.$emit('canceled', { modalVisibility: false })
  }
}
