import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { Pagination } from '@/domain/entities/Pagination'
import { FAQ, FAQs, QnA, FAQSubCategory, QnASubCategory, StarQuestion } from '@/domain/entities/FAQ'
import {
  CreateFAQRequest,
  UpdateFAQRequest,
  UpdateFAQCategoryOrderRequest,
  UpdateFAQCategoryStatusRequest,
  UpdateFAQDraftStatusRequest,
  UploadFAQCategoryImageRequest,
  ReorderStarQuestionRequest,
} from '@/data/payload/api/FAQRequest'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import store from '@/app/ui/store'
import { FAQPresenter } from '../presenters/FAQPresenter'

export interface FAQState {
  isLoading: boolean
  isAppendLoading: boolean
  faqData: FAQ[]
  faqDetail: FAQ
  starQuestionList: StarQuestion[]
  paginationData: Pagination
}

interface PayloadReorderStarQnA {
  id?: number
  starOrder?: number
  iconImage?: string
}

@Module({ namespaced: true, store, name: 'faq', dynamic: true })
class FAQControlller extends VuexModule implements FAQState {
  private presenter: FAQPresenter = container.resolve(FAQPresenter)
  public isLoading = false
  public isAppendLoading = false
  public faqData = [new FAQ()]
  public faqDetail = new FAQ()
  public starQuestionList = [new StarQuestion()]
  public paginationData = new Pagination()
  public constants = {
    maxCategoryName: 50,
    maxSubCategoryName: 50,
    maxQuestion: 100,
    maxAnswer: 3000,
  }
  public tabOptions = {
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
  }

  @Action({ rawError: true })
  public getFAQList(form: { params: Record<string, any>; isAppend?: boolean }) {
    form.isAppend ? this.setAppendLoading(true) : this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (!form.isAppend) {
          this.setFAQData(res)
        } else {
          this.appendFAQData(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch FAQ List Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        form.isAppend ? this.setAppendLoading(false) : this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getFAQDetail(faqCategoryId: string) {
    this.setLoading(true)

    this.presenter
      .get(faqCategoryId)
      .then(res => {
        this.setFAQDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch FAQ Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getListQuestion() {
    this.setLoading(true)

    this.presenter
      .getListQuestion()
      .then((res) => {
        this.setStarQuestionList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Starred Question Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public addFAQDetailToList(faqCategoryId: string) {
    this.setAppendLoading(true)

    this.presenter
      .get(faqCategoryId)
      .then(res => {
        this.appendFAQDetailToList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch FAQ Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setAppendLoading(false)
      })
  }

  @Action({ rawError: true })
  public createFAQ(form: {
    categoryName: string
    isActive: boolean
    withSubCategory: boolean
    iconImage: string
    data: QnA[]
    subCategoryList: FAQSubCategory[]
  }) {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateFAQRequest(
          form.categoryName,
          form.isActive,
          form.withSubCategory,
          form.iconImage,
          form.data.map(item => {
            item.answer = Utils.takeASCII(item.answer || '')
            return item
          }),
          form.subCategoryList.map(
            (item) => new FAQSubCategory(
              item.subCategory,
              item.order,
              item.data?.map(unit => {
                unit.answer = Utils.takeASCII(unit.answer || '')
                return unit
              })
            )
          ),
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.CREATE_FAQ_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Create FAQ Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateFAQ(form: {
    categoryId: string
    categoryName: string
    isActive: boolean
    data: QnA[]
    withSubCategory: boolean
    subCategoryList: QnASubCategory[]
    iconImage: string,
    isEdit?: boolean
  }) {
    this.setLoading(true)

    this.presenter
      .update(
        form.categoryId,
        new UpdateFAQRequest(
          form.categoryName,
          form.isActive,
          form.data.map(item => {
            item.answer = Utils.takeASCII(item.answer || '')
            return item
          }),
          form.withSubCategory,
          form.subCategoryList.map(
            (item) => new QnASubCategory(
              item.id,
              item.order,
              item.subCategoryName,
              item.data?.map(unit => {
                unit.answer = Utils.takeASCII(unit.answer || '')
                return unit
              })
            )
          ),
          form.iconImage
        )
      )
      .then(() => {
        if (form.isEdit) {
          EventBus.$emit(EventBusConstants.UPDATE_FAQ_SUCCESS, {
            id: form.categoryId,
          })
        } else {
          EventBus.$emit(EventBusConstants.DETAIL_FAQ_SUCCESS, {
            id: form.categoryId,
          })
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Update FAQ Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateFAQCategoryOrder(form: {
    categoryId: string
    toOrderNumber: number
  }) {
    this.setLoading(true)

    this.presenter
      .updateCategoryOrder(
        form.categoryId,
        new UpdateFAQCategoryOrderRequest(form.toOrderNumber)
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_REORDER_FAQ_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Reorder FAQ Category Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateFAQCategoryStatus(form: {
    categoryId: string
    isActive: boolean
  }) {
    this.setLoading(true)

    this.presenter
      .updateCategoryStatus(
        form.categoryId,
        new UpdateFAQCategoryStatusRequest(form.isActive)
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_FAQ_STATUS_SUCCESS, {
          id: form.categoryId,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update FAQ Category Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateFAQDraftStatus(form: {
    categoryId: string
    faqId: number
    isDraft: boolean
  }) {
    this.setLoading(true)

    this.presenter
      .updateFAQDraftStatus(
        form.categoryId,
        new UpdateFAQDraftStatusRequest(form.faqId, form.isDraft)
      )
      .then(() => {
        if (form.isDraft) {
          EventBus.$emit(EventBusConstants.UNPOST_FAQ_SUCCESS, {
            id: form.categoryId,
          })
        } else {
          EventBus.$emit(EventBusConstants.REPOST_FAQ_SUCCESS, {
            id: form.categoryId,
          })
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Unpost/Repost FAQ Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteFAQ(form: { categoryId: string; faqId: string }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      `{"faqId": ${form.faqId}}`,
      'snake_case'
    )

    this.presenter
      .deleteFAQ(form.categoryId, formattedParams)
      .then(() => {
        EventBus.$emit(EventBusConstants.DELETE_FAQ_SUCCESS, {
          id: form.categoryId,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete FAQ Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteSubCategory(categoryId: number) {
    this.setLoading(true)

    this.presenter
      .deleteSubCategory(categoryId)
      .then(() => {
        EventBus.$emit(EventBusConstants.DELETE_SUB_CATEGORY_SUCCESS, {
          id: categoryId,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Sub Category Name Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async postImageCategory(image: File) {
    this.setLoading(true)
    return this.presenter
      .postImageCategory(new UploadFAQCategoryImageRequest(image))
      .then(url => {
        return url
      })
      .catch(error => {
        Vue.notify({
          title: 'Upload Image Category Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async starUnstarQuestion(value: { idQuestion: number, action: string }) {
    this.setLoading(true)
    return this.presenter
      .starredQuestion(value.idQuestion, value.action)
      .then(() => {
        if (value.action === 'star') {
          EventBus.$emit(EventBusConstants.STAR_QUESTION_SUCCESS, {
            id: value.idQuestion,
          })
        } else if (value.action === 'unstar') {
          EventBus.$emit(EventBusConstants.UNSTAR_QUESTION_SUCCESS, {
            id: value.idQuestion,
          })
        }
        return true
      })
      .catch(error => {
        Vue.notify({
          title: 'Star / Unstar Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async reorderStarQuestion(payload: PayloadReorderStarQnA[]) {
    this.setLoading(true)
    return this.presenter
      .reorderStarQuestion(new ReorderStarQuestionRequest(payload))
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_REORDER_STAR_QUESTION_SUCCESS)
        return true
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Star Question Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setAppendLoading(bool: boolean) {
    this.isAppendLoading = bool
  }

  @Mutation
  private setFAQDetail(faq: FAQ) {
    this.faqDetail = faq
  }

  @Mutation
  private setStarQuestionList(data: StarQuestion[]) {
    this.starQuestionList = data
  }

  @Mutation
  private appendFAQDetailToList(faq: FAQ) {
    this.faqData = (<FAQ[]>this.faqData).map((item: FAQ) => {
      if (item.id === faq.id) {
        item = {
          id: faq.id,
          categoryName: faq.categoryName,
          order: faq.order,
          questionsCount: faq.questionsCount,
          questionsData: faq.questionsData,
          isActive: faq.isActive,
          iconImage: faq.iconImage,
          subCategoryList: faq.subCategoryList,
          withSubCategory: faq.withSubCategory,
          createdAt: faq.createdAt,
          updatedAt: faq.updatedAt,
        }
      }

      return item
    })
  }

  @Mutation
  private setFAQData(faqs: FAQs) {
    this.faqData = faqs.data as FAQ[]
    this.paginationData = faqs.pagination as Pagination
  }

  @Mutation
  private appendFAQData(faqs: FAQs) {
    if (faqs.data) {
      this.faqData.push(...faqs.data)
    }
    this.paginationData = faqs.pagination as Pagination
  }
}

export default getModule(FAQControlller)
