



































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Draggable from 'vuedraggable'
import controller from '@/app/ui/controllers/FAQController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LeaveModal from '@/app/ui/components/LeaveModal/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import QnAItem from '../components/QnAItem/index.vue'
import CategoryImage from '../components/CategoryImage/index.vue'
import { FAQSubCategory, QnA } from '@/domain/entities/FAQ'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { Route, NavigationGuardNext } from 'vue-router'

interface Questions {
  question: string
  answer: string
  order: number
}

interface SubCategory {
  subCategory: string
  order: number
  isOpen: boolean
  questions: Questions[]
}

@Component({
  mixins: [validationMixin],
  components: {
    Draggable,
    TextInput,
    LoadingOverlay,
    Modal,
    LeaveModal,
    QnAItem,
    AddIcon,
    DragIcon,
    CaretDownIcon,
    TrashIcon,
    Toggle,
    Button,
    CategoryImage,
    RadioButton
  },
})
export default class CreateFAQPage extends Vue {
  controller = controller
  successModal = false
  leavePromptModal = false
  nextNavigate = {} as NavigationGuardNext
  confirmationModal = false
  form = {
    categoryName: '',
    iconImage: '',
    isActive: false,
    questionsFAQ: [
      {
        question: '',
        answer: '',
        order: 1,
      }
    ] as Questions[],
    subCategoryFAQ: [
      {
        subCategory: '',
        order: 1,
        isOpen: true,
        questions: [
          {
            question: '',
            answer: '',
            order: 1
          }
        ],
      }
    ] as SubCategory[]
  }
  isEditing = false
  isSubmiting = false
  isValidate = false
  optionTabs = [
    {
      label: 'No Sub Category',
      value: 'no-sub-category',
      name: 'noSubCategory',
    },
    {
      label: 'With Sub Category',
      value: 'with-sub-category',
      name: 'withSubCategory',
    }
  ]
  currentTab = 'no-sub-category'

  beforeMount(): void {
    window.addEventListener('beforeunload', this.warnClosing)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.warnClosing)
    })
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing && !this.isSubmiting) {
      this.nextNavigate = next
      this.leavePromptModal = true
      if (this.leavePromptModal) {
        return
      }
    }
    next()
  }

  created(): void {
    EventBus.$on(EventBusConstants.CREATE_FAQ_SUCCESS, () => {
      this.confirmationModal = false
      this.successModal = true
    })
  }

  @Validations()
  validations() {
    if (this.currentTab === 'no-sub-category') {
      return {
        form: {
          categoryName: {
            required,
            maxLength: maxLength(this.controller.constants.maxCategoryName),
          },
          iconImage: {
            required
          },
          questionsFAQ: {
            required,
            minLength: minLength(1),
            $each: {
              question: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(this.controller.constants.maxQuestion),
              },
              answer: {
                required,
                minLength: minLength(3),
                maxLength: (text: string) =>
                  Utils.stripTags(text).length <=
                  this.controller.constants.maxAnswer,
              },
            },
          },
        },
      }
    } else {
      return {
        form: {
          categoryName: {
            required,
            maxLength: maxLength(this.controller.constants.maxCategoryName),
          },
          iconImage: {
            required
          },
          subCategoryFAQ: {
            required,
            minLength: minLength(1),
            $each: {
              subCategory: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(this.controller.constants.maxSubCategoryName),
              },
              questions: {
                required,
                minLength: minLength(1),
                $each: {
                  question: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(this.controller.constants.maxQuestion),
                  },
                  answer: {
                    required,
                    minLength: minLength(3),
                    maxLength: (text: string) =>
                      Utils.stripTags(text).length <=
                      this.controller.constants.maxAnswer,
                  },
                }
              }
            },
          },
        },
      }
    }
  }

  @Watch('form', { deep: true })
  onFormChanged(): void {
    this.isEditing = true
  }

  get numberOfQuestion(): number | undefined {
    if (this.currentTab === 'no-sub-category') {
      return this.form.questionsFAQ.length
    } else if (this.currentTab === 'with-sub-category') {
      let rows = [] as Questions[]
      this.form.subCategoryFAQ.forEach(item => {
        rows = rows.concat(item.questions)
      })
      return rows.length
    } else {
      return 0
    }
  }

  private onLeavePage() {
    if (this.nextNavigate) {
      this.leavePromptModal = false
      this.nextNavigate()
    }
  }

  private warnClosing(event: {
    preventDefault: () => void
    returnValue: string
  }) {
    if (!this.isEditing || this.isSubmiting) return
    event.preventDefault()
    event.returnValue = ''
  }

  private onDragged() {
    if (this.currentTab === 'no-sub-category') {
      this.form.questionsFAQ = this.form.questionsFAQ.map((item, idx) => {
        item.order = idx + 1
        return item
      })
    } else if (this.currentTab === 'with-sub-category') {
      this.form.subCategoryFAQ = this.form.subCategoryFAQ.map((item) => {
        const result = item.questions.map((unit, idx) => {
          return { ...unit, order: idx + 1 }
        })
        return { ...item, questions: result }
      })
    }
  }

  private onDraggedSubCategory() {
    this.form.subCategoryFAQ = this.form.subCategoryFAQ.map((item, idx) => {
      item.order = idx + 1
      return item
    })
  }

  private onChangeTab(key: string) {
    // No Sub Category
    this.isValidate = false
    if (key === 'no-sub-category') {
      let rows = [] as Questions[]
      this.form.subCategoryFAQ.forEach(item => {
        rows = rows.concat(item.questions)
      })
      const result = rows.map((item, idx) => {
        return { ...item, order: idx + 1 }
      })
      this.form.questionsFAQ = result
    } else if (key === 'with-sub-category') {
      this.form.subCategoryFAQ = [
        {
          subCategory: "",
          order: 1,
          isOpen: true,
          questions: this.form.questionsFAQ
        }
      ]
    }
  }

  private addQnA(idx: number) {
    // With Sub Category
    if (this.currentTab === 'with-sub-category') {
      this.form.subCategoryFAQ[idx].questions.push({
        question: '',
        answer: '',
        order: this.form.subCategoryFAQ[idx].questions.length + 1
      })
    } else if (this.currentTab === 'no-sub-category') {
      this.form.questionsFAQ.push({
        question: '',
        answer: '',
        order: this.form.questionsFAQ.length + 1
      })
    }
  }

  private addSubCategory() {
    this.form.subCategoryFAQ.push({
      subCategory: '',
      order: this.form.subCategoryFAQ.length + 1,
      isOpen: true,
      questions: [
        {
          question: '',
          answer: '',
          order: 1
        },
      ],
    })
  }

  private removeQnA(order: number, orderSub: number | undefined) {
    // With Sub Category
    if (orderSub) {
      this.form.subCategoryFAQ[orderSub - 1].questions = this.form.subCategoryFAQ[orderSub - 1].questions
        .filter((item) => item.order !== order)
        .map((q) => {
          if (q.order > order) {
            q.order = q.order - 1
          }
          return q
        })
    } else {
      this.form.questionsFAQ = this.form.questionsFAQ
        .filter((item) => item.order !== order)
        .map((q) => {
          if (q.order > order) {
            q.order = q.order - 1
          }
          return q
        })
    }
  }

  private onRemoveSubCategory(order: number) {
    this.form.subCategoryFAQ = this.form.subCategoryFAQ
      .filter((item) => item.order !== order)
      .map((sub) => {
        if (sub.order > order) {
          sub.order = sub.order - 1
        }
        return sub
      })
  }

  private onToggleSubCategory(order: number, isOpen:boolean) {
    this.form.subCategoryFAQ[order - 1].isOpen = !isOpen
  }

  private onToggleStatus($event: { target: { checked: boolean } }) {
    this.form.isActive = $event.target.checked
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'FAQListPage' })
  }

  private async onFileChange(file: File) {
    if (file) {
      const image = await controller.postImageCategory(file)
      this.form.iconImage = image as string
    } else {
      this.form.iconImage = ''
    }
  }

  private onCreateFAQ() {
    this.confirmationModal = false
    this.isValidate = true
    if (!this.$v.form.$invalid) {
      this.isSubmiting = true
      if (this.currentTab === 'with-sub-category') {
        controller.createFAQ({
          categoryName: this.form.categoryName,
          isActive: this.form.isActive,
          withSubCategory: true,
          iconImage: this.form.iconImage,
          data: [],
          subCategoryList: this.form.subCategoryFAQ.map(
            (item) => new FAQSubCategory(
              item.subCategory,
              item.order,
              item.questions?.map((unit) => new QnA(undefined, unit.question, unit.answer, unit.order))
            )
          ),
        })
      } else if (this.currentTab === 'no-sub-category') {
        controller.createFAQ({
          categoryName: this.form.categoryName,
          isActive: this.form.isActive,
          withSubCategory: false,
          iconImage: this.form.iconImage,
          data: this.form.questionsFAQ.map(
            (item) => new QnA(undefined, item.question, item.answer, item.order)
          ),
          subCategoryList: [],
        })
      }
    } else {
      Vue.notify({
        title: 'Create FAQ Invalid',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.CREATE_FAQ_SUCCESS)
  }
}
